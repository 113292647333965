import { documentReady } from 'web-utility';
import { HTTPError } from 'koajax';
import { createCell, render } from 'web-cell';

import session from './model/Session';
import PageRoot from './page';

window.addEventListener('unhandledrejection', ({ reason }) => {
    if (!(reason instanceof HTTPError)) return;

    const { status, message } = reason;

    if (status === 401) session.goToLarkOAuth();
    else alert(message);
});

(async () => {
    try {
        await session.signInLark();
    } catch {}

    await session.getUser();

    await documentReady;

    render(<PageRoot />);

    if (!location.hash) location.hash = '/';
})();
