import { TypeKeys } from 'web-utility';
import { observable } from 'mobx';

import { service } from './service';

export interface Base {
    objectId: string;
    createdAt: string;
    updatedAt: string;
}

export abstract class BaseModel {
    @observable
    downloading = false;

    @observable
    uploading = false;
}

export function toggle<T extends BaseModel>(field: TypeKeys<T, boolean>) {
    return (target: any, key: string, meta: PropertyDescriptor) => {
        const origin: (...data: any[]) => any = meta.value;

        meta.value = function (...data: any[]) {
            this[field] = true;

            const result = origin.apply(this, data),
                end = () => (this[field] = false);

            if (result instanceof Promise) result.finally(end);
            else end();

            return result;
        };
    };
}

export interface ListData<T extends Base> {
    count: number;
    data: T[];
}

export abstract class ListModel<D extends Base> extends BaseModel {
    abstract rootPath: string;

    @observable
    current: D = {} as D;

    @observable
    list: D[] = [];

    clearCurrent() {
        this.current = {} as D;
    }

    clearList() {
        this.list = [];
    }

    @toggle('downloading')
    async getOne(id: string) {
        const { body } = await service.get<D>(`${this.rootPath}/${id}`);

        return (this.current = body);
    }

    @toggle('downloading')
    async getList() {
        const { body } = await service.get<ListData<D>>(this.rootPath);

        return (this.list = body.data);
    }

    @toggle('uploading')
    async updateOne({ objectId, ...data }: Partial<D>) {
        const { body } = await (objectId
            ? service.patch<D>(`${this.rootPath}/${objectId}`, data)
            : service.post<D>(this.rootPath, data));

        return (this.current = body);
    }
}
