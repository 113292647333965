import { service, setToken } from './service';
import { BaseModel, toggle } from './Base';
import { User } from './User';

interface SessionUser extends User {
    token: string;
}

class SessionModel extends BaseModel {
    user?: User;

    @toggle('downloading')
    async getUser() {
        const { body } = await service.get<User>('session');

        return (this.user = body);
    }

    goToLarkOAuth() {
        location.href =
            service.baseURI + 'session/Lark/Web?redirect=' + location.origin;
    }

    @toggle('uploading')
    async signInLark() {
        const URI = new URL(location.href);
        const code = URI.searchParams.get('code');

        if (!code) throw new Error('OAuth code is not found');

        const {
            body: { token, ...user }
        } = await service.post<SessionUser>('session/Lark/Web', {
            code
        });
        URI.searchParams.delete('code');
        URI.searchParams.delete('state');
        history.replaceState(null, document.title, URI);

        setToken(token);
        return (this.user = user);
    }
}

export default new SessionModel();
