import { createCell, Fragment } from 'web-cell';
import { createRouter } from 'cell-router';

import { RequirementList } from './Requirement';
import { EvaluatorPage } from './Requirement/Evaluator';

export const { Route, Link } = createRouter();

export default () => (
    <>
        <Route path="/" component={RequirementList} />
        <Route path="/requirement/:rid?" component={EvaluatorPage} />
    </>
);
