import { createCell } from 'web-cell';
import classNames from 'classnames';

type FieldProps = JSX.IntrinsicElements['input'] &
    JSX.IntrinsicElements['textarea'] &
    JSX.IntrinsicElements['select'];

export interface FloatFieldProps extends FieldProps {
    as?: 'input' | 'textarea' | 'select';
}

export function FloatField({
    className,
    as: Tag = 'input',
    name,
    id,
    placeholder,
    ...props
}: FloatFieldProps) {
    id ||= name;

    return (
        <div className={classNames('form-floating', className)}>
            <Tag
                className="form-control h-100"
                {...{ id, name, placeholder, ...props }}
            />
            <label htmlFor={id}>{placeholder}</label>
        </div>
    );
}
