import { HTTPClient } from 'koajax';

const { protocol, hostname } = location;

var token: string = localStorage.token;

export const setToken = (raw: string) => (token = localStorage.token = raw);

export const service = new HTTPClient({
    baseURI:
        protocol === 'https:' || hostname === '0.0.0.0'
            ? 'https://idea2app.avosapps.us/'
            : `http://${hostname}:3000/`,
    responseType: 'json'
}).use(({ request }, next) => {
    if (token)
        request.headers = {
            ...request.headers,
            Authorization: `Bearer ${token}`
        };
    return next();
});
