import { DevelopmentScope, UnitPrice } from '../../model/Requirement';

export const DevelopmentScopeName = {
    [DevelopmentScope.Prototype]: '产品原型',
    [DevelopmentScope.Design]: '界面设计',
    [DevelopmentScope.Desktop]: '桌面端',
    [DevelopmentScope.Mobile]: '移动端',
    [DevelopmentScope.Service]: '服务端'
};

export const UnitPriceName = {
    [UnitPrice.Low]: '低档（￥600）',
    [UnitPrice.Medium]: '中档（￥800）',
    [UnitPrice.High]: '高档（￥1000）'
};
