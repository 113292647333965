import { Base, ListModel } from './Base';

export enum DevelopmentScope {
    Prototype,
    Design,
    Desktop,
    Mobile,
    Service
}

export enum UnitPrice {
    Low = 600,
    Medium = 800,
    High = 1000
}

export interface Requirement extends Base {
    title: string;
    description: string;
    models: string[];
    scopes: DevelopmentScope[];
    developerCount: number;
    price: UnitPrice;
    factor: number;
    workload: number;
    monthPeriod: number;
    budget: number;
}

export class RequirementModel extends ListModel<Requirement> {
    rootPath = 'requirement';
}

export default new RequirementModel();
