import { createCell, WebCell, component, observer } from 'web-cell';
import { PageProps } from 'cell-router';

import { Link } from '..';
import { Button } from '../../component/Button';
import requirement from '../../model/Requirement';

@component({
    tagName: 'requirement-list'
})
@observer
export class RequirementList extends WebCell<PageProps>() {
    connectedCallback() {
        requirement.getList();
    }

    disconnectedCallback() {
        requirement.clearList();
    }

    render() {
        const { list } = requirement;

        return (
            <main className="container">
                <h1>软件开发需求库</h1>

                <Button className="my-4" href="#/requirement/">
                    新增
                </Button>
                <ol>
                    {list.map(({ objectId, title }) => (
                        <li key={objectId}>
                            <Link to={`/requirement/${objectId}`}>{title}</Link>
                        </li>
                    ))}
                </ol>
            </main>
        );
    }
}
