import classNames from 'classnames';
import { createCell } from 'web-cell';

type ButtonLike = JSX.IntrinsicElements['button'] & JSX.IntrinsicElements['a'];

export type Variant =
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'link';

export interface ButtonProps extends ButtonLike {
    variant?: Variant | `outline-${Exclude<Variant, 'link'>}`;
}

export function Button({
    className,
    variant = 'primary',
    href,
    defaultSlot,
    ...props
}: ButtonProps) {
    const Tag = href ? 'a' : 'button';

    return (
        <Tag
            className={classNames('btn', `btn-${variant}`, className)}
            {...{ href, ...props }}
        >
            {defaultSlot}
        </Tag>
    );
}
