import { createCell } from 'web-cell';
import classNames from 'classnames';

type InputProps = JSX.IntrinsicElements['input'];

export interface ToggleFieldProps extends InputProps {
    type: 'radio' | 'checkbox';
}

export const ToggleField = ({
    className,
    style,
    id,
    defaultSlot,
    ...props
}: ToggleFieldProps) => (
    <div className={classNames('form-check', className)} style={style}>
        <input
            className="form-check-input"
            id={id}
            value={defaultSlot + ''}
            {...props}
        />
        <label className="form-check-label text-nowrap" htmlFor={id}>
            {defaultSlot}
        </label>
    </div>
);
