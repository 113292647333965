import { makeArray, formToJSON } from 'web-utility';
import { createCell, WebCell, component, observer, reaction } from 'web-cell';
import { observable } from 'mobx';
import { PageProps } from 'cell-router';

import { Button } from '../../component/Button';
import { FloatField } from '../../component/FloatField';
import { ToggleField } from '../../component/ToggleField';
import requirement, { Requirement } from '../../model/Requirement';
import { DevelopmentScopeName, UnitPriceName } from './data';

const currencyFormatter = new Intl.NumberFormat('zh-CN', {
    style: 'currency',
    currency: 'CNY'
});

@component({
    tagName: 'evaluator-page'
})
@observer
export class EvaluatorPage extends WebCell<PageProps>() {
    @observable
    rid?: string;

    connectedCallback() {
        this.loadData(this.rid);
    }

    disconnectedCallback() {
        this.loadData();
    }

    @reaction(({ rid }: EvaluatorPage) => rid)
    loadData(rid?: string) {
        if (rid) requirement.getOne(rid);
        else requirement.clearCurrent();
    }

    handleSave = async (event: SubmitEvent) => {
        event.preventDefault();
        event.stopPropagation();

        var { description, models, scopes, ...data } = formToJSON<
            Partial<Requirement>
        >(event.currentTarget as HTMLFormElement);

        models = makeArray(models);
        scopes = makeArray(scopes);

        if (!scopes.length) return alert('请选开发范围');

        const { objectId } = await requirement.updateOne({
            ...data,
            description: description.trim(),
            models: models[0] && models,
            scopes
        });
        if (this.rid !== objectId) location.hash = `/requirement/${objectId}`;
    };

    renderBasis() {
        const { title = '', description = '' } = requirement.current;

        return (
            <section>
                <FloatField
                    className="my-3"
                    name="title"
                    required
                    defaultValue={title}
                    placeholder="标题"
                />
                <FloatField
                    as="textarea"
                    name="description"
                    required
                    defaultValue={description}
                    placeholder="需求描述"
                />
            </section>
        );
    }

    renderModel() {
        const { models } = requirement.current;

        return (
            <section className="d-flex flex-wrap">
                {models?.map(model => (
                    <ToggleField
                        key={model}
                        id={model}
                        className="me-3"
                        type="checkbox"
                        name="models"
                        checked
                    >
                        {model}
                    </ToggleField>
                ))}
            </section>
        );
    }

    renderScope() {
        const { scopes } = requirement.current;

        return (
            <section className="d-flex flex-wrap">
                {Object.entries(DevelopmentScopeName).map(([value, name]) => (
                    <ToggleField
                        key={name}
                        id={name}
                        className="me-3"
                        type="checkbox"
                        name="scopes"
                        value={value}
                        defaultChecked={scopes?.includes(+value)}
                    >
                        {name}
                    </ToggleField>
                ))}
            </section>
        );
    }

    renderPrice() {
        const { price } = requirement.current;

        return (
            <section className="d-flex flex-wrap">
                {Object.entries(UnitPriceName).map(
                    ([value, name], index, { length }) => (
                        <ToggleField
                            key={name}
                            id={name}
                            className="me-3"
                            type="radio"
                            name="price"
                            value={value}
                            defaultChecked={
                                price === +value ||
                                (!price && index + 1 === length)
                            }
                        >
                            {name}
                        </ToggleField>
                    )
                )}
            </section>
        );
    }

    renderFactor() {
        const { developerCount = 1, factor = 1 } = requirement.current;

        return (
            <section className="d-flex flex-wrap">
                <FloatField
                    className="me-3"
                    type="number"
                    name="developerCount"
                    required
                    min="1"
                    defaultValue={developerCount + ''}
                    placeholder="工程师人数"
                />
                <FloatField
                    type="number"
                    name="factor"
                    required
                    min="1"
                    defaultValue={factor + ''}
                    placeholder="风险系数"
                />
            </section>
        );
    }

    renderBudget() {
        const { workload, monthPeriod, budget } = requirement.current;

        return (
            <ul>
                <li>
                    工作量：
                    <strong className="text-danger">{workload}人天</strong>
                </li>
                <li>
                    工期：
                    <strong className="text-danger">{monthPeriod}自然月</strong>
                </li>
                <li>
                    费用：
                    <strong className="text-danger">
                        {budget && currencyFormatter.format(budget)}
                    </strong>
                </li>
            </ul>
        );
    }

    render() {
        const {
            downloading,
            uploading,
            current: { objectId }
        } = requirement;

        return (
            <form
                className="container"
                onSubmit={this.handleSave}
                onReset={() => history.back()}
            >
                <h1 className="my-4">软件系统开发需求评估</h1>

                <p className="text-muted m-0">
                    动效复杂的纯前端页面、数据爬虫等直接按人天数评估，无法套用以下算法。
                </p>
                <input type="hidden" name="objectId" value={objectId} />

                <h2 className="my-3">基础信息</h2>
                {this.renderBasis()}

                <h2 className="my-3">领域模型</h2>
                {this.renderModel()}

                <h2 className="my-3">开发范围</h2>
                {this.renderScope()}

                <h2 className="my-3">人天单价</h2>
                {this.renderPrice()}

                <h2 className="my-3">立项参数</h2>
                {this.renderFactor()}

                <h2 className="my-3">粗估预算</h2>
                {this.renderBudget()}

                <footer className="d-flex my-3">
                    <Button
                        className="flex-fill me-3"
                        type="submit"
                        variant="primary"
                        disabled={downloading || uploading}
                    >
                        评估
                    </Button>
                    <Button className="flex-fill" type="reset" variant="danger">
                        取消
                    </Button>
                </footer>
            </form>
        );
    }
}
